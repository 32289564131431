import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UpdaterService {
  lastDate: number = null;
  isChecking = false;
  constructor() { }

  public init() {
    this.lastDate = new Date().getTime();
  }

  public async check() {
    const currentTime = new Date().getTime();
    const mins = (currentTime - this.lastDate) / 60000;
    if (this.isChecking || mins < 10) { return; } else {
      try {
        this.isChecking = true;
        this.lastDate = new Date().getTime();
        const reg = await navigator.serviceWorker.getRegistration();
        await reg.update();
        this.isChecking = false;
      } catch (ex) {
        this.isChecking = false;
      }
    }
  }
}
