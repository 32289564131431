import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { PreloadImageComponent } from './preload-image/preload-image.component';
import { InstallModalComponent } from './install-modal/install-modal.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { TimelineComponent } from './timeline/timeline.component';
import { SplashComponent } from './splash/splash.component';
import { UpdateModalComponent } from './update-modal/update-modal.component';
import { PreloadImageWpComponent } from './preload-image-wp/preload-image-wp.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
  ],
  declarations: [
    PreloadImageComponent,
    InstallModalComponent,
    SideMenuComponent,
    TimelineComponent,
    SplashComponent,
    UpdateModalComponent,
    PreloadImageWpComponent
  ],
  exports: [
    PreloadImageComponent,
    InstallModalComponent,
    SideMenuComponent,
    TimelineComponent,
    SplashComponent,
    UpdateModalComponent,
    PreloadImageWpComponent
  ],
  entryComponents: [
    PreloadImageComponent,
    InstallModalComponent,
    SideMenuComponent,
    TimelineComponent,
    SplashComponent,
    UpdateModalComponent,
    PreloadImageWpComponent
  ],
})
export class ComponentsModule {}
