import { Injectable } from '@angular/core';
// import { initializeApp, messaging, database, auth } from 'firebase';
import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/database';
import '@firebase/messaging';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor() { }

  initializeFirebase() {
    return new Promise((res, rej) => {
      if (!firebase.messaging.isSupported()) {
        rej();
      } else {
        firebase.initializeApp({
          apiKey: 'AIzaSyD31IKsCXaYlMX1gdSmPC7Pw-ZrZgrBSHg',
          authDomain: 'pwaosdc.firebaseapp.com',
          databaseURL: 'https://pwaosdc.firebaseio.com',
          projectId: 'pwaosdc',
          storageBucket: 'pwaosdc.appspot.com',
          messagingSenderId: '673112731704'
        });
        // firebase.messaging().useServiceWorker(registration);
        if (navigator.serviceWorker) {
          navigator.serviceWorker.getRegistration().then(reg => {
            firebase.messaging().useServiceWorker(reg);
            res();
          });
        }
      }
      res();
    });
  }

  pushNotificationIsAvaliable() {
    return firebase.messaging.isSupported();
  }

  async disableNotifications() {
    const tk = await firebase.messaging().getToken();
    if (tk) {
      firebase.messaging().deleteToken(tk).then(() => {
        localStorage.removeItem('tokenPush');
      });

    }
  }

  async askForPermissioToReceiveNotifications() {
    try {
      await firebase.messaging().requestPermission();
      const token = await firebase.messaging().getToken();
      // console.log('token do usuário:', token);
      this.saveRegId(token);
      firebase.messaging().onTokenRefresh(() => {
        firebase.messaging().getToken()
        .then(refreshedToken => {
          this.saveRegId(refreshedToken);
        }).catch( err => { });
      });
      return token;
    } catch (error) {
      console.error(error);
    }
  }

  saveRegId(tk) {
    const curToken  = localStorage.getItem('tokenPush');
    if (curToken !== tk) {
      firebase.auth().signInWithEmailAndPassword(atob('amF2aUBlc21lcmFydGUuY29t'), atob('cHdhb3NkY3B1c2g=')).then(() => {
        firebase.database().ref('ids/').push({
          id: tk
        }).then(() => {
          localStorage.setItem('tokenPush', tk);
        }).catch(() => {});
      }).catch(() => {});
    } else { }
  }
}
