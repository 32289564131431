import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { LenguageService } from './lenguage.service';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  private cachePostsByID = [];
  private cachePostsBySlug = [];
  private tOut = 7500;

  constructor(public http: HttpClient, private langServ: LenguageService) { }

  public getPosts(offset: number) {
    return this.http.get('https://www.osondocamino.es/wp-json/wp/v2/posts?_embed&per_page=10&offset='
      + offset + ((this.langServ.currentLang !== 'es') ? '&lang=' + this.langServ.currentLang : ''))
    .pipe(timeout(this.tOut)).toPromise();
  }
  public getPost(id: string) {
    if (this.cachePostsByID[id]) {
      return new Promise((resolve, reject) => {
        resolve(this.cachePostsByID[id]);
      });
    } else {
      return this.http.get('https://www.osondocamino.es/wp-json/wp/v2/posts/' + id + '?_embed')
        .pipe(timeout(this.tOut)).toPromise().then((data: any) => {
        this.cachePostsByID[id] = data;
        this.cachePostsBySlug[data.slug] = data;
        return data;
      });
    }
  }
  public getPostBySlug(slug: string) {
    const parseslug = slug.replace(/^\//, '').replace(/\/$/, '');
    if (this.cachePostsBySlug[parseslug]) {
      return new Promise((resolve, reject) => {
        resolve([this.cachePostsBySlug[parseslug]]);
      });
    } else {
      return this.http.get('https://www.osondocamino.es/wp-json/wp/v2/posts?_embed&slug=' + encodeURI(slug)
        + ((this.langServ.currentLang !== 'es') ? '&lang=' + this.langServ.currentLang : ''))
      .pipe(timeout(this.tOut)).toPromise().then((data: any) => {
        if (data[0]) {
          this.cachePostsByID[data[0].id] = data[0];
          this.cachePostsBySlug[data[0].slug] = data[0];
        }
        return data;
      });
    }
  }
}
