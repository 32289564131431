import { Component, ViewChild } from '@angular/core';
import { Platform, AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
import { InstallModalComponent } from './components/install-modal/install-modal.component';
import { LinkerService } from './services/linker.service';
import { PushNotificationService } from './services/push-notification.service';
import { UpdateModalComponent } from './components/update-modal/update-modal.component';
import { LenguageService } from './services/lenguage.service';
import { UpdaterService } from './services/updater.service';

declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  instalable = false;
  prompt = null;
  showSplash = true;
  updating = false;
  @ViewChild('menu')menubar;

  constructor(
    private platform: Platform,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalControler: ModalController,
    private router: Router,
    private lnk: LinkerService,
    private navCntlr: NavController,
    private pushNoty: PushNotificationService,
    private langServ: LenguageService,
    private updateService: UpdaterService
  ) {
    this.initializeApp();
  }

  menuClick(option) {
    this.navCntlr.navigateRoot(option.url, {
      replaceUrl: true
    });
  }

  installApp() {
    if (this.prompt) {
      this.prompt.prompt();
      this.prompt.userChoice.then((choiceResult: any) => {
        this.instalable = false;
      });
    }
  }

  async startUpdateDialod(swReg) {
    const mdl = await this.modalControler.create({
      backdropDismiss: false,
      component: UpdateModalComponent,
      showBackdrop: true
    });
    await mdl.present();
  }

  async updateDialog(e: any) {
    const alert = await this.alertController.create({
      header: 'Actualización',
      message: 'Actualización completa',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Aplicar',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Aplicando...',
              spinner: 'crescent',
              translucent: true,
            });
            await loading.present();
            e.detail.postMessage({ updateSw: true });
          }
        }
      ]
    });
    await alert.present();
  }

  async installModal() {
    const modal = await this.modalControler.create({
      component: InstallModalComponent,
      backdropDismiss: true,
      showBackdrop: true,
      cssClass: 'installModal'
    });
    modal.present();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.lnk.init();
      this.updateService.init();
      this.router.events.subscribe( (e) => {
        if (e instanceof NavigationEnd) {
          /*descomentar y poner analitycs id
          if (gtag !== null) {
            gtag('config', 'UA-112384807-3', {
              'page_path': e.urlAfterRedirects
            });
          }*/
          this.updateService.check();
        }
      });

      if (this.platform.is('iphone') || this.platform.is('ipad')) {
        const notInstaled = !(window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone);
        if (notInstaled) {
          this.installModal();
        } else {
          const isFirstInstaledOpen = (window.localStorage.getItem('firstopen') === null);
          if (isFirstInstaledOpen) {
            if (gtag !== null) {
              /*descomentar y poner analitycs id
              window.localStorage.setItem('firstopen', 'captured');
              gtag('config', 'UA-112384807-3', {
                'page_path': 'ios-install'
              });*/
            }
          }
        }
      } else if (this.platform.is('android')) {
        window.addEventListener('appInstall', (e: any) => {
          this.prompt = e.detail;
          this.instalable = !(window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone);
          if (!this.instalable) {
            const isFirstInstaledOpen = (window.localStorage.getItem('firstopen') === null);
            if (isFirstInstaledOpen) {
              if (gtag !== null) {
                /*descomentar y poner analitycs id
                window.localStorage.setItem('firstopen', 'captured');
                gtag('config', 'UA-112384807-3', {
                  'page_path': 'ios-install'
                });*/
              }
            }
          }
        });
      }
      window.addEventListener('appStartUpdate', (e: any) => {
        this.updating = true;
        this.startUpdateDialod(e.detail);
      }, false);
      window.addEventListener('appUpdate', (e: any) => {
        this.updateDialog(e);
      }, false);
      this.menubar.disabled = false;
      if ((this.platform.is('iphone') || this.platform.is('android')) && screen.availWidth < 992) {
        this.menubar.open().then(() => {
          this.menubar.close().then(() => {
            this.showSplash = false;
          });
        });
      } else {
        this.showSplash = false;
      }
      this.pushNoty.initializeFirebase().then(() => {
        this.pushNoty.askForPermissioToReceiveNotifications();
      });
    });
  }

}
