import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-update-modal',
  templateUrl: './update-modal.component.html',
  styleUrls: ['./update-modal.component.scss']
})
export class UpdateModalComponent {
  progress = 1;
  roundProgres = 100;
  constructor(private modalControler: ModalController) {
    this.start();
  }

  start() {
    const sw = (window as  any).installingSw;
    if (sw) {
      navigator.serviceWorker.addEventListener('message', event => {
        if (event.data.progressInstall) {
          this.progress = event.data.progressInstall / 100;
          this.roundProgres = Math.floor(event.data.progressInstall);
        }
      });
      sw.postMessage({ progressInstall: true });
    } else {
      this.modalControler.dismiss();
    }
  }
}
