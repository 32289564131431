import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { timeout, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArtistLoaderService {

  private tOut = 7500;
  public favs = [];
  public artistas = [];
  constructor(public http: HttpClient) {
    this.loadFavs();
  }

  loadFavs() {
    const fs = localStorage.getItem('favs');
    if (fs) {
      this.favs = JSON.parse(fs);
    }
  }
  saveFavs() {
    localStorage.setItem('favs', JSON.stringify(this.favs));
  }

  addFav(idFav) {
    const art = this.artistas.find(a => a.id === idFav);
    art.fav = true;
    this.favs.push(idFav);
    this.saveFavs();
  }

  removeFav(idFav) {
    const art = this.artistas.find(a => a.id === idFav);
    art.fav = false;
    const index = this.favs.indexOf(idFav);
    if (index > -1) {
      this.favs.splice(index, 1);
      this.saveFavs();
    }
  }

  filterArtist(searchTern: string) {
    return (searchTern) ? this.artistas.filter(item => item.nombre.toLowerCase().indexOf(searchTern.toLowerCase()) > -1) : this.artistas;
  }

  filterArtistFavs() {
    return this.artistas.filter(item => item.fav);
  }

  public loadArtist() {
    return this.http.get('assets/data/artistas.json').pipe(timeout(this.tOut)).toPromise().then((data: any) => {
      const dtaArr = data.artistas.sort((a, b) => {
        const keyA = a.nombre.toLowerCase(),
            keyB = b.nombre.toLowerCase();
        if (keyA < keyB) { return -1; }
        if (keyA > keyB) { return 1; }
        return 0;
      }).map(art => {
        art.fav = this.favs.includes(art.id);
        return art;
      });
      this.artistas = dtaArr;
      return data;
    });
  }

  public getArtist(id: string) {
    for (const artist of this.artistas) {
      if (artist.id === id) {
        return artist;
      }
    }
    return null;
  }
}
