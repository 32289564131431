import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LenguageService {

  currentLang = 'es';

  constructor() {
    this.loadLang();
  }

  setLang(lng) {
    this.currentLang = lng;
    localStorage.setItem('lang', lng);
  }

  loadLang() {
    this.currentLang = localStorage.getItem('lang') || 'es';
  }
}
