import { Component, OnInit, Renderer2, ElementRef, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-preload-image-wp',
  templateUrl: './preload-image-wp.component.html',
  styleUrls: ['./preload-image-wp.component.scss']
})
export class PreloadImageWpComponent  {

  _src = '';
  _ratio: { w: number, h: number };

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private http: HttpClient
  ) {}

  @Input() set id(val: string) {
    this.http.get('https://www.osondocamino.es/wp-json/wp/v2/media?include=' + val).toPromise().then((data: any) => {
      this._src = data[0].media_details.sizes.medium.source_url;
    }).catch(() => {
      this._src = 'assets/imgs/noimagebg.png';
    });
  }

  @Input() set ratio(ratio: { w: number, h: number }) {
    this._ratio = ratio || {w: 1, h: 1};
  }

  _loaded(isLoaded: boolean) {
    if (isLoaded) {
      setTimeout(() => {
        this._renderer.addClass(this._elementRef.nativeElement, 'img-loaded');
      }, 500);
    } else {
      this._renderer.removeClass(this._elementRef.nativeElement, 'img-loaded');
    }
  }
}
