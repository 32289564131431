import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'inicio',
    loadChildren: './pages/inicio/inicio.module#InicioPageModule'
  },
  { path: 'blog', loadChildren: './pages/blog/blog.module#BlogPageModule' },
  { path: 'blog/:id', loadChildren: './pages/entrada/entrada.module#EntradaPageModule' },
  { path: 'artistas', loadChildren: './pages/artistas/artistas.module#ArtistasPageModule' },
  { path: 'artistas/:id', loadChildren: './pages/artista/artista.module#ArtistaPageModule' },
  { path: 'info', loadChildren: './pages/informacion/informacion.module#InformacionPageModule' },
  { path: 'pagina/:id', loadChildren: './pages/pagina/pagina.module#PaginaPageModule' },
  { path: 'image-viwer/:src', loadChildren: './pages/image-viwer/image-viwer.module#ImageViwerPageModule' },
  { path: 'info/:data', loadChildren: './pages/info/info.module#InfoPageModule' },
  { path: 'horarios', loadChildren: './pages/horarios/horarios.module#HorariosPageModule' },
  { path: 'map', loadChildren: './pages/map/map.module#MapPageModule' },
  { path: 'firmas', loadChildren: './pages/firmas/firmas.module#FirmasPageModule' },
  { path: 'sponsors', loadChildren: './pages/sponsors/sponsors.module#SponsorsPageModule' },
  { path: 'horarios/:dia', loadChildren: './pages/horarios/horarios.module#HorariosPageModule' },
  {
    path: '**',
    redirectTo: 'inicio',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
