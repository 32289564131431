import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { LenguageService } from '../../services/lenguage.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent  {
  public appPages = [
    {
      title: 'Inicio',
      url: '/inicio',
      icon: 'home',
      isRoot: true,
      active: false
    },
    {
      title: 'Artistas',
      url: '/artistas',
      icon: 'star',
      isRoot: true,
      active: false
    },
    {
      title: 'Horarios',
      url: '/horarios',
      icon: 'time',
      isRoot: true
    },
    {
      title: 'Info',
      url: '/info',
      icon: 'information-circle',
      isRoot: true,
      active: false
    },
    {
      title: 'Mapa',
      url: '/map',
      icon: 'map',
      isRoot: true,
      active: false
    },
    {
      title: 'Firmas',
      url: '/firmas',
      icon: 'brush',
      isRoot: true,
      active: false
    },
    {
      title: 'Patrocinadores',
      url: '/sponsors',
      icon: 'star',
      isRoot: true,
      active: false
    }
  ];

  constructor(private navCntlr: NavController, public langServ: LenguageService, private router: Router) {
    this.router.events.subscribe( (e) => {
      if (e instanceof NavigationEnd) {
        for (let i = 0; i < this.appPages.length; i++) {
          this.appPages[i].active = (e.urlAfterRedirects === this.appPages[i].url);
        }
      }
    });
  }

  menuClick(option) {
    this.navCntlr.navigateRoot(option.url, {
      replaceUrl: true
    });
  }

  langChange(ev) {
    this.langServ.setLang((ev.target.checked) ? 'gl' : 'es');
  }
}
