import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { LenguageService } from './lenguage.service';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  private tOut = 7500;

  constructor(public http: HttpClient, private langServ: LenguageService) { }

  getPageBySlug(slug: string) {
    return this.http.get('https://www.osondocamino.es/wp-json/wp/v2/pages?_embed&slug=' + encodeURI(slug)
      + ((this.langServ.currentLang !== 'es') ? '&lang=' + this.langServ.currentLang : ''))
    .pipe(timeout(this.tOut)).toPromise();
  }

  getPage(id: string) {
    return this.http.get('https://www.osondocamino.es/wp-json/wp/v2/pages/'  + id + '?_embed')
    .pipe(timeout(this.tOut)).toPromise();
  }
}
