import { Injectable } from '@angular/core';
import { NavController, ToastController, Platform } from '@ionic/angular';
import { PostService } from './post.service';
import { PagesService } from './pages.service';

const imageTypes = ['jpg', 'png', 'jpeg', 'gif'];
const newTabTypes = ['pdf', 'txt', 'html'];

const customRedirect = {
  '/entradas/': {
    url: 'entradas'
  },
  '/entradas-2019/': {
    url: 'entradas'
  }
};

@Injectable({
  providedIn: 'root'
})
export class LinkerService {

  public lastFetchedData = {
    type: '',
    data: null
  };

  constructor(private navControler: NavController,
              private toastCtrl: ToastController,
              private posts: PostService,
              private pages: PagesService,
              private platform: Platform) { }

  private findParent(tagname, el) {
    while (el) {
      if ((el.nodeName || el.tagName).toLowerCase() === tagname.toLowerCase()) {
        return el;
      }
      el = el.parentNode;
    }
    return null;
  }

  private urlExec(elemt, url) {
    if (url.startsWith(window.origin)) {
      return true;
    } else if (url.startsWith('http://osondocamino.es')
      || url.startsWith('https://osondocamino.es')
      || url.startsWith('http://www.osondocamino.es')
      || url.startsWith('https://www.osondocamino.es')) {
      const slug = url.replace('http://osondocamino.es', '')
                        .replace('https://osondocamino.es', '')
                        .replace('http://www.osondocamino.es', '')
                        .replace('https://www.osondocamino.es', '').split('?')[0];
      if (slug.startsWith('/#') || slug === '/' || slug === '') { return; }
      const extension: string = slug.split('.').pop();
      if (customRedirect[slug]) {
        this.navControler.navigateRoot(customRedirect[slug].url, {
          replaceUrl: true
        });
      } else if (imageTypes.indexOf(extension.toLocaleLowerCase()) !== -1) {
          this.openImage(url);
      } else if (newTabTypes.indexOf(extension.toLocaleLowerCase()) !== -1) {
        this.openNewWindow(url);
        // window.open(url);
      } else {
        this.toastCtrl.create({
          message: 'Cargando...',
          animated: true
        }).then( t => {
          t.present();
          console.log(slug);
          Promise.all([this.posts.getPostBySlug(slug), this.pages.getPageBySlug(slug)]).then( (values: any) => {
            // console.log(values);
            if (values[0].length) {
              this.lastFetchedData.type = 'entrada';
              this.lastFetchedData.data = values[0][0];
              this.navControler.navigateForward('blog/' + values[0][0].id, {
                queryParams: {
                  data: 0
                }
              });
            } else if (values[1].length) {
              this.lastFetchedData.type = 'pagina';
              this.lastFetchedData.data = values[1][0];
              this.navControler.navigateForward('pagina/' + values[1][0].id, {
                queryParams: {
                  data: 0
                }
              });
            } else {
              this.toastCtrl.create({
                message: 'No se ha podido obtener la información',
                duration: 2000,
                animated: true,
                closeButtonText: 'OK',
                showCloseButton: true
              }).then(te => {
                te.present();
              });
            }
            t.dismiss();
          }).catch((err) => {
            console.log(err);
            this.toastCtrl.create({
              message: 'No se ha podido obtener la información',
              duration: 2000,
              animated: true,
              closeButtonText: 'OK',
              showCloseButton: true
            }).then(te => {
              t.dismiss();
              te.present();
            });
          });
        });
      }
    } else {
      // window.open(url);
      this.openNewWindow(url);
    }
  }

  public init() {
    // console.log('linker init');
    document.body.addEventListener('click', (e: any) => {
      const a = this.findParent('a', e.target);
      if (a && a.href) {
        if (a.hasAttribute('download')) { return true; }
        this.urlExec(a, a.href);
        e.preventDefault();
        e.stopPropagation();
        return false;
      } else {
        const img = this.findParent('img', e.target);
        if (img && img.hasAttribute('href')) {
          this.urlExec(img, img.getAttribute('href'));
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
      }
      return true;
    });
  }
  public openImage(src: string) {
    this.navControler.navigateForward(`image-viwer/${encodeURIComponent(src)}`);
  }

  public navigateRootTo(comp, params = {}) {
    this.navControler.navigateRoot(comp, params);
  }

  private openNewWindow(url) {
    if (this.platform.is('iphone') || this.platform.is('ipad')) {
      if ((window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone)) {
        if (url.startsWith('mailto:')) {
          window.open('http://www.osondocamino.es/wp-content/uploads/2019/05/emailosdc-1.html?email='
            + url.replace('mailto:', ''), '_blank');
        } else if (url.match(/^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/)) {
            window.open('http://www.osondocamino.es/wp-content/uploads/2019/05/url-1.html?url='
            + url, '_blank');
        } else {
          window.open(url, '_blank');
        }
      } else {
        window.open(url, '_blank');
      }
    } else {
      window.open(url, '_blank');
    }
    // window.open(url, '_blank');
  }
}
