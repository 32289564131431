import { Component, OnInit, Input } from '@angular/core';
import { ArtistLoaderService } from '../../services/artist-loader.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  @Input()slots: any;
  @Input()fav: boolean;
  @Input()esc1Name: string;
  @Input()esc2Name: string;

  constructor(public artisLoader: ArtistLoaderService) { }

  ngOnInit() {
  }

}
